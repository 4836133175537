'use client';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Arrow, Button, Entering, Typography } from 'components';
import { ContentCreatorIcon, MagicIcon, TranslationIcon } from 'lib/Icons';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useFeatureFlags } from 'providers/ClientProviders/useFeatureFlags';
import { useCurrentUser } from 'providers/UserProvider/useCurrentUser';
import { useState } from 'react';
import { cn } from 'utils/cn';
import Notifications from './_components/Notifications';
import ProductivityOverview from './_components/ProductivityOverview';
import RecentActivities from './_components/RecentActivities';
import Savings from './_components/Savings';

const AiPage = () => {
  const t = useTranslations();
  const { data } = useCurrentUser();
  const [email, setEmail] = useState('');

  const { data: featureFlags } = useFeatureFlags();

  return (
    <>
      <div
        className={cn(
          'w-7xl sticky top-20 z-50 w-[calc(100%_-_4px)] max-w-7xl bg-[hsl(200deg_60%_98.04%_/_0%)]',
          'backdrop-blur-md transition-colors duration-200 ease-in-out',
          'pb-8 pt-10 md:ml-1 md:pl-3 md:pr-8 lg:ml-1 lg:pl-[28px] lg:pr-8'
        )}
      >
        <div className="flex w-full justify-between">
          <Entering position="top" delay={0.2} duration={0.2} offset={5}>
            <Typography variant="medium7xl" className="!m-0 text-tertiary-100 dark:text-white">
              {t('components.menu.title.dashboard_ai', {
                username: data?.firstName
                  ? `${data?.firstName} ${data?.lastName ?? ''}`
                  : data?.name
              })}
            </Typography>
          </Entering>
          <div>
            <Menu>
              {({ open }) => (
                <>
                  <MenuButton>
                    <Entering position="top" delay={0.2} duration={0.2} offset={5}>
                      <Button
                        endAdornment={
                          <span className="pl-2">
                            <Arrow open={!open} />
                          </span>
                        }
                        colorIcon="white"
                        label={t('dashboard_ai.buttons.quick_actions')}
                        className="!px-4"
                      />
                    </Entering>
                  </MenuButton>
                  <Transition
                    enter="transition ease-out duration-75"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <MenuItems
                      anchor="bottom end"
                      className="mt-2 w-40 origin-top-right rounded-md bg-white p-1 text-sm/6 text-red-500 shadow-custom-light [--anchor-gap:var(--spacing-1)] focus:outline-none"
                    >
                      <MenuItem>
                        <Link
                          href="/smart-tasks/chat-ai"
                          className="group flex w-full items-center gap-1 rounded-3xl p-1.5 data-[focus]:bg-primary-blue-900"
                        >
                          <div className="relative h-6 w-6">
                            <MagicIcon className="absolute left-0.5 top-1 h-6 w-6 !text-tertiary-200 group-hover:!text-primary-blue-100" />
                          </div>
                          <Typography
                            variant="regular3"
                            className="text-tertiary-200 group-hover:!text-primary-blue-100"
                          >
                            {t('dashboard_ai.buttons.options.smart_tasks')}
                          </Typography>
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link
                          href="/dashboard/translation-requests"
                          className="group flex w-full items-center gap-1 rounded-3xl p-1.5 data-[focus]:bg-primary-blue-900"
                        >
                          <TranslationIcon className="h-6 w-6 scale-[1.3] p-1 !text-tertiary-200 group-hover:!text-primary-blue-100" />
                          <Typography
                            variant="regular3"
                            className="text-tertiary-200 group-hover:!text-primary-blue-100"
                          >
                            {t('dashboard_ai.buttons.options.translation')}
                          </Typography>
                        </Link>
                      </MenuItem>
                      {featureFlags?.contentGenerationEnabled && (
                        <MenuItem>
                          <Link
                            href="/content-creator"
                            className="group flex w-full items-center gap-1 rounded-3xl p-1.5 data-[focus]:bg-primary-blue-900"
                          >
                            <ContentCreatorIcon className="h-7 w-6 p-1 !text-tertiary-200 group-hover:!text-primary-blue-100" />
                            <Typography
                              variant="regular3"
                              className="text-tertiary-200 group-hover:!text-primary-blue-100"
                            >
                              {t('dashboard_ai.buttons.options.content_creator')}
                            </Typography>
                          </Link>
                        </MenuItem>
                      )}
                    </MenuItems>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <div className="m-8 mt-0 grid h-[calc(100%_-_123px)] grid-cols-[minmax(600px,1fr)_409px] gap-6">
        <Entering position="right" delay={0.4} duration={0.2} offset={20}>
          <RecentActivities />
        </Entering>
        <div className="flex flex-col gap-6">
          <Entering position="left" delay={0.4} duration={0.2} offset={20}>
            <Notifications />
          </Entering>
          <Entering position="right" delay={0.6} duration={0.2} offset={20}>
            <ProductivityOverview />
          </Entering>
          <Entering position="left" delay={0.8} duration={0.2} offset={20}>
            <Savings />
          </Entering>
        </div>
      </div>
    </>
  );
};

export default AiPage;
