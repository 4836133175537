'use client';
import { Button, Typography } from 'components';
import { ChartNotFoundClipArt } from 'lib/ClipArt';
import { useTranslations } from 'next-intl';

const Savings = () => {
  const t = useTranslations();

  // TODO
  return null;

  return (
    <div className="rounded-md bg-white p-5 !pt-3 shadow-custom">
      <div className="flex justify-between">
        <Typography variant="medium2" className="text-tertiary-100 dark:text-white">
          {t('dashboard_ai.savings.title')}
        </Typography>
        <Button
          variant="text"
          size="small"
          className="!p-0"
          label={t('dashboard_ai.savings.overview')}
        />
      </div>
      <hr className="border-b-0.5 mb-4 mt-2 border-tertiary-600" />
      <div className="my-16 flex flex-col items-center justify-center">
        <ChartNotFoundClipArt />
        <Typography variant="light4" className="!mt-4 text-tertiary-200 dark:text-white">
          {t('dashboard_ai.savings.not_found')}
        </Typography>
      </div>
    </div>
  );
};

export default Savings;
