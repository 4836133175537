'use client';
import { Typography } from 'components';
import { Comment, Maybe, Scalars, Thread, TranslationRequest } from 'gql/graphql';
import { RecentActivitiesNotFoundClipArt } from 'lib/ClipArt';
import _ from 'lodash';
import { useTranslations } from 'next-intl';
import { useRecentActivities } from 'providers/ActivitiesProviders/useRecentActivities';
import { getDayEnd } from 'utils/time/getDayEnd';
import BreakerCard from './BreakerCard';
import DashboardCards from './DashboardCards';

export enum KeysRecentActivities {
  TranslationRequestCreated = 'translation_request.create',
  TranslationRequestChanged = 'translation_request.status_change',
  CommentCreated = 'comment.create',
  ThreadCreated = 'universal_chat_thread.create',
  GeneratedContentCreate = 'generated_content.create'
}

export type Activity = {
  id: Scalars['ID'];
  key: KeysRecentActivities;
  parameters?: Maybe<Scalars['JSON']>;
  trackable: Partial<Comment & Thread & TranslationRequest>;
};

const RecentActivities = () => {
  const t = useTranslations();

  const { data: dataRecentActivities } = useRecentActivities();

  const data = Object.entries(
    _.groupBy(dataRecentActivities?.nodes ?? [], (activity) =>
      getDayEnd(activity.trackable?.updatedAt)
    )
  );

  return (
    <div className="flex flex-1 flex-col rounded-md bg-white p-5 !pt-3 shadow-custom">
      <Typography variant="medium2" className="!m-0 text-tertiary-100 dark:text-white">
        {t('dashboard_ai.recent_activities.title')}
      </Typography>
      <hr className="border-b-0.5 mb-4 mt-2 border-tertiary-600" />
      {!!dataRecentActivities?.nodes?.length ? (
        <div className="flex flex-col">
          {data?.map(([updatedAt, dataActivities]) => {
            const today = getDayEnd();
            const dateIso = getDayEnd(updatedAt);

            return (
              <div key={updatedAt}>
                {today !== dateIso && <BreakerCard date={updatedAt} />}
                <DashboardCards activities={dataActivities} />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-1 flex-col items-center justify-center">
          <div className="my-6 flex flex-col items-center justify-center">
            <RecentActivitiesNotFoundClipArt className="scale-75" />
            <Typography variant="medium1" className="!mt-4 text-tertiary-100 dark:text-white">
              {t('dashboard_ai.recent_activities.not_found.title')}
            </Typography>
            <Typography variant="light4" className="text-tertiary-200 dark:text-white">
              {t('dashboard_ai.recent_activities.not_found.paragraph')}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecentActivities;
