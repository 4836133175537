import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CommentsFilterEnum, TranslationRequestPage } from 'gql/graphql';
import { gql } from 'graphql-request';
import { TGenericError } from 'types/TGenericError';
import clientUtil from 'utils/clientUtil';

type TData = TranslationRequestPage;

export const useTranslationRequestsComments = (
  options: Omit<UseQueryOptions<unknown, TGenericError, TData>, 'queryKey'> = {}
) => {
  const client = clientUtil();

  const graphql = gql`
    query translationRequests($comments: CommentsFilterEnum, $skipPagination: Boolean) {
      translationRequests(comments: $comments, skipPagination: $skipPagination) {
        nodes {
          title # TR title
          comments {
            task {
              id
            }
            writtenByClient
            read
          }
        }
      }
    }
  `;

  return useQuery<unknown, TGenericError, TData>({
    ...options,
    queryKey: ['useTranslationRequestsComments'],
    queryFn: async () =>
      client.request({
        data: {
          gql: graphql,
          variables: {
            skipPagination: true,
            comments: CommentsFilterEnum.Pending
          }
        }
      })
  });
};
