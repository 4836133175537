'use client';
import { Button, Typography } from 'components';
import { getComments } from 'factories/getComments';
import { NotificationsNotFoundClipArt } from 'lib/ClipArt';
import { CommentDashboardAiIcon } from 'lib/Icons';
import { useTranslations } from 'next-intl';
import { useTranslationRequestsComments } from 'providers/TranslationRequests/useTranslationRequestsComments';
import { cn } from 'utils/cn';

const Notifications = () => {
  const t = useTranslations();
  const { data: notifications } = useTranslationRequestsComments();

  const handleLink = (id: string) => () => {
    if (typeof window !== 'undefined') window.location.href = `/dashboard/show-task/${id}#comments`;
  };

  return (
    <div className="rounded-md bg-white !pt-3 shadow-custom">
      <span className="flex gap-2 px-5">
        <Typography variant="medium2" className="text-tertiary-100 dark:text-white">
          {t('dashboard_ai.notifications.title')}
        </Typography>
        {!!notifications?.nodes?.length && (
          <div className="mt-1 flex h-[23px] w-fit rounded-full bg-secondary-red px-2">
            <Typography
              variant="medium4"
              className=" !m-0 !p-0 text-center !leading-[25px] text-white"
            >
              {notifications?.nodes?.length}
            </Typography>
          </div>
        )}
      </span>
      <hr className="border-b-0.5 mt-2 border-tertiary-600" />
      <div className="flex max-h-[294px] flex-col gap-4 overflow-auto">
        {!!notifications?.nodes?.length ? (
          <>
            {notifications?.nodes?.map((notification) => {
              const { commentsNew } = getComments(notification?.comments);
              const words = (notification?.title ?? '').split(' ');

              return (
                <div
                  key={notification.id}
                  className="mx-5 flex gap-2 rounded-4xl border border-tertiary-600 p-2 first:mt-5 last:mb-5"
                >
                  <CommentDashboardAiIcon className="mt-1 h-6 w-6 min-w-[24px] scale-75" />
                  <div className="mt-0.5 flex">
                    <Typography
                      variant="light4"
                      className="group !m-0 !mt-[3px] flex flex-wrap text-tertiary-100 dark:text-white"
                    >
                      {t('dashboard_ai.notifications.paragraph', { comments: commentsNew || '' })}:
                      &nbsp;
                      {words.map((word, index) => (
                        <Button
                          key={word}
                          size="small"
                          variant="text"
                          label={word}
                          onClick={handleLink(notification?.comments?.at(0)?.task?.id ?? '')}
                          className={cn(
                            '!whitespace-no-wrap !h-auto !min-w-0 !p-0 !pb-[3px]',
                            'group-hover:opacity-[0.85]',
                            index !== words?.length - 1 ? 'mr-1' : ''
                          )}
                          classNameTypography="break-all text-left"
                        />
                      ))}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="my-16 flex flex-col items-center justify-center">
            <NotificationsNotFoundClipArt />
            <Typography variant="light4" className="!mt-4 text-tertiary-200 dark:text-white">
              {t('dashboard_ai.notifications.not_found')}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
