'use client';
import { Typography } from 'components';
import { RecentActivity, TranslationRequestStatusEnum } from 'gql/graphql';
import {
  AiSessionDashboardAiIcon,
  CommentDashboardAiIcon,
  ContentCreatorDashboardAiIcon,
  TranslationCancelledDashboardAiIcon,
  TranslationCreatedDashboardAiIcon,
  TranslationFinishedDashboardAiIcon,
  TranslationInProgressDashboardAiIcon,
  TranslationQuotedDashboardAiIcon,
  TranslationReadyToReviewDashboardAiIcon
} from 'lib/Icons';
import { useTranslations } from 'next-intl';
import { FC, useEffect, useRef, useState } from 'react';
import { cn } from 'utils/cn';
import { timeShort } from 'utils/time/timeShort';
import { Activity, KeysRecentActivities } from '.';

interface IDashboardCards {
  activities?: RecentActivity[];
}

const DashboardCards: FC<IDashboardCards> = ({ activities }) => {
  const t = useTranslations();
  const refFirstCard = useRef<HTMLDivElement>(null);
  const refLastCard = useRef<HTMLDivElement>(null);

  const [distance, setDistance] = useState<number>(0);

  const handleLink = (href: string) => () => {
    if (typeof window !== 'undefined') window.location.href = href;
  };

  const getInitTextHrefTitleAndEndText = (activity: Activity) => {
    if (
      activity?.key === KeysRecentActivities.TranslationRequestChanged ||
      activity?.key === KeysRecentActivities.TranslationRequestCreated
    )
      return {
        href:
          activity?.key === KeysRecentActivities.TranslationRequestCreated
            ? '#'
            : activity?.parameters?.to_status === TranslationRequestStatusEnum.Unordered
            ? `/dashboard/translation-requests?quote=${activity.trackable?.version}`
            : `/dashboard/show-translation/${activity.trackable?.version}`,
        init: t('dashboard_ai.recent_activities.states.tr.title'),
        title: activity.trackable?.title,
        status: activity?.parameters?.to_status,
        ...{
          ...(activity?.parameters?.to_status === TranslationRequestStatusEnum.InTranslation && {
            end: t('dashboard_ai.recent_activities.states.tr.states.in_progress')
          }),
          ...(activity?.parameters?.to_status === TranslationRequestStatusEnum.Unordered && {
            end: t('dashboard_ai.recent_activities.states.tr.states.quoted')
          }),
          ...(activity?.parameters?.to_status === TranslationRequestStatusEnum.WaitingForClient && {
            end: t('dashboard_ai.recent_activities.states.tr.states.ready_to_review')
          }),
          ...(activity?.parameters?.to_status === TranslationRequestStatusEnum.Finished && {
            end: t('dashboard_ai.recent_activities.states.tr.states.finished')
          }),
          ...(activity?.parameters?.to_status === TranslationRequestStatusEnum.Canceled && {
            end: t('dashboard_ai.recent_activities.states.tr.states.cancelled')
          }),
          ...(!activity?.parameters?.to_status && {
            end: t('dashboard_ai.recent_activities.states.tr.states.created')
          })
        }
      };

    if (activity?.key === KeysRecentActivities.CommentCreated)
      return {
        href: `/dashboard/show-task/${activity.trackable?.task?.id}#comments`,
        init: t('dashboard_ai.recent_activities.states.comment.title'),
        title: activity.trackable?.translationRequest?.title,
        text: activity.trackable?.text,
        status: '',
        end: ''
      };

    if (activity?.key === KeysRecentActivities.ThreadCreated) {
      const title = activity.trackable?.title;

      return {
        href: `/smart-tasks/chat-ai/${activity.trackable?.id}`,
        init: t('dashboard_ai.recent_activities.states.chat.title'),
        title: title?.at(title.length - 1) === '.' ? title?.slice(0, -1) : title,
        status: '',
        end: ''
      };
    }

    if (activity?.key === KeysRecentActivities.GeneratedContentCreate) {
      const title = activity.trackable?.title;

      return {
        href: `/content-creator/${activity.trackable?.id}`,
        init: t('dashboard_ai.recent_activities.states.content_generation.title'),
        title: title?.at(title.length - 1) === '.' ? title?.slice(0, -1) : title,
        status: '',
        end: ''
      };
    }

    return { href: '', init: '', title: '', text: '', status: '', end: '' };
  };

  useEffect(() => {
    if (refFirstCard.current && refLastCard.current) {
      const firstRect = refFirstCard.current.getBoundingClientRect();
      const lastRect = refLastCard.current.getBoundingClientRect();

      setDistance(Math.abs(lastRect.top - firstRect.top));
    }
  }, [refFirstCard, refLastCard, setDistance]);

  return (
    <div className="relative flex flex-col">
      <span
        className="absolute left-[11px] mt-4 h-20 w-20 border-l-[2px] border-tertiary-800"
        style={{ height: distance }}
      />
      {activities?.map((activity, index) => {
        const { href, init, title, text, status, end } = getInitTextHrefTitleAndEndText(
          activity as Activity
        );

        if (!Object.values(KeysRecentActivities).includes(activity.key as KeysRecentActivities))
          return null;

        return (
          <div key={activity.id} className="flex gap-4 rounded-4xl py-2">
            <div
              className="relative h-6 w-6"
              {...{
                ...(index === 0 && { ref: refFirstCard }),
                ...(index === activities?.length - 1 && { ref: refLastCard })
              }}
            >
              {(activity.key === KeysRecentActivities.TranslationRequestChanged ||
                activity.key === KeysRecentActivities.TranslationRequestCreated) && (
                <>
                  {!status && (
                    <TranslationCreatedDashboardAiIcon className={cn('absolute -top-0.5 mt-1')} />
                  )}
                  {status === TranslationRequestStatusEnum.InTranslation && (
                    <TranslationInProgressDashboardAiIcon
                      className={cn('absolute -top-0.5 mt-1')}
                    />
                  )}
                  {status === TranslationRequestStatusEnum.Unordered && (
                    <TranslationQuotedDashboardAiIcon className={cn('absolute -top-0.5 mt-1')} />
                  )}
                  {status === TranslationRequestStatusEnum.WaitingForClient && (
                    <TranslationReadyToReviewDashboardAiIcon
                      className={cn('absolute -top-0.5 mt-1')}
                    />
                  )}
                  {status === TranslationRequestStatusEnum.Finished && (
                    <TranslationFinishedDashboardAiIcon className={cn('absolute -top-0.5 mt-1')} />
                  )}
                  {status === TranslationRequestStatusEnum.Canceled && (
                    <TranslationCancelledDashboardAiIcon className={cn('absolute -top-0.5 mt-1')} />
                  )}
                </>
              )}
              {activity.key === KeysRecentActivities.CommentCreated && (
                <CommentDashboardAiIcon className={cn('absolute -top-0.5 mt-1')} />
              )}
              {activity.key === KeysRecentActivities.ThreadCreated && (
                <AiSessionDashboardAiIcon className={cn('absolute -top-0.5 mt-1')} />
              )}
              {activity.key === KeysRecentActivities.GeneratedContentCreate && (
                <ContentCreatorDashboardAiIcon className={cn('absolute -top-0.5 mt-1')} />
              )}
            </div>
            <div className="flex w-full flex-col pl-1">
              <div>
                <Typography
                  variant="light3"
                  className="!mb-0 !mt-[3px] text-tertiary-100 dark:text-white"
                >
                  {init}
                </Typography>
                <span>&nbsp;</span>
                {href === '#' ? (
                  <Typography variant="medium3" className="!mt-[3px] mb-0 text-tertiary-100">
                    {title}
                  </Typography>
                ) : (
                  <Typography
                    variant="medium3"
                    className="!mt-[3px] mb-0 cursor-pointer text-secondary-link hover:opacity-80"
                    onClick={handleLink(href)}
                  >
                    {title}
                  </Typography>
                )}
                {end && <>&nbsp;</>}
                <Typography
                  variant="light3"
                  className="!mt-[3px] mb-0 text-tertiary-100 dark:text-white"
                >
                  {end}
                  {activity.key !== KeysRecentActivities.ThreadCreated && <span>.</span>}
                </Typography>
              </div>
              <Typography variant="light5" className="text-tertiary-200">
                {timeShort(activity.trackable?.updatedAt)}
              </Typography>
              {activity.key === KeysRecentActivities.CommentCreated && text && (
                <div className="mt-2 w-full rounded-xl bg-tertiary-800 p-3">
                  <Typography
                    variant="light4"
                    className="!mt-[3px] mb-0 text-tertiary-200 dark:text-white"
                  >
                    {text}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DashboardCards;
