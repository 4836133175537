import { Typography } from 'components';
import { useTranslations } from 'next-intl';
import { FC } from 'react';
import { dateTimeShort } from 'utils/time/dateTimeShort';

interface IBreakerCard {
  date: string;
}

const BreakerCard: FC<IBreakerCard> = ({ date }) => {
  const t = useTranslations();

  return (
    <div className="relative my-4 flex flex-col items-center justify-center">
      <hr className="border-b-0.5 w-full border-dashed border-tertiary-400" />
      <Typography
        variant="light4"
        className="absolute -top-3 bg-white px-2 text-tertiary-200 dark:text-white"
      >
        {dateTimeShort(date, true, t as any)}
      </Typography>
    </div>
  );
};

export default BreakerCard;
