import { Comment, Maybe } from 'gql/graphql';

export const getComments = (commentsObj?: Maybe<Comment[]> | undefined) =>
  commentsObj
    ?.filter((comment) => !comment.writtenByClient)
    ?.reduce(
      (acc, value) => ({
        comments: acc.comments + 1,
        commentsNew: acc.commentsNew + (value?.read ? 0 : 1)
      }),
      { comments: 0, commentsNew: 0 }
    ) ?? { comments: 0, commentsNew: 0 };
