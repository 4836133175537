'use client';
import 'chart.js/auto';
import { Typography } from 'components';
import dayjs from 'dayjs';
import { ProductivityMetricsChartData } from 'gql/graphql';
import { ProductivityClipArt } from 'lib/ClipArt';
import { ArrowUpIcon } from 'lib/Icons';
import { useTranslations } from 'next-intl';
import { useProductivityMetrics } from 'providers/ProductivityRequests/useProductivityMetrics';
import { Line } from 'react-chartjs-2';
import theme from 'theme.json';
import { cn } from 'utils/cn';

const ProductivityOverview = () => {
  const t = useTranslations();
  const { data: dataProductivity } = useProductivityMetrics({
    fromDate: dayjs().startOf('day').toISOString(),
    toDate: dayjs().toISOString(),
    fromDateBefore: dayjs().subtract(7, 'day').startOf('day').toISOString(),
    toDateBefore: dayjs().subtract(1, 'day').endOf('day').toISOString()
  });

  const chartData = [
    ...(dataProductivity?.currentProductivityMetrics?.chartData || []),
    ...(dataProductivity?.productivityMetricsBefore?.chartData || [])
  ].sort((a: ProductivityMetricsChartData, b: ProductivityMetricsChartData) =>
    a.timestamp.localeCompare(b.timestamp)
  );
  const score =
    ((dataProductivity?.currentProductivityMetrics?.score || 0) +
      (dataProductivity?.productivityMetricsBefore?.score || 0)) /
    2;
  const change =
    ((dataProductivity?.currentProductivityMetrics?.change || 0) +
      (dataProductivity?.productivityMetricsBefore?.change || 0)) /
    2;

  const changeIsNegative = change < 0;

  const dataset = [...(chartData ?? [])]?.reduce(
    (acc, data) => ({
      x: [
        ...acc.x,
        new Intl.DateTimeFormat('en-US', {
          month: 'short',
          day: 'numeric'
        }).format(new Date(data.timestamp))
      ],
      y: [...acc.y, (data.value ?? '') as string]
    }),
    { x: [], y: [] } as { x: string[]; y: string[] }
  ) ?? { x: [], y: [] };

  const getGradient = (ctx: any, chartArea: any) => {
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0.9, theme.extend.colors.primary.green[300]);
    gradient.addColorStop(0, 'white');
    return gradient;
  };

  const data = {
    labels: dataset.x,
    datasets: [
      {
        label: '  ' + t('dashboard_ai.productivity_overview.productivity'),
        data: dataset.y,
        fill: true,
        backgroundColor: function (context: any) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          // This case happens on initial chart load
          if (!chartArea) return;
          return getGradient(ctx, chartArea);
        },
        borderColor: theme.extend.colors.secondary.green,
        cubicInterpolationMode: 'monotone' as const,
        tension: 0.9,
        pointStyle: false as unknown as 'false'
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    tooltip: {
      usePointStyle: true
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        display: false
      }
    }
  };

  return (
    <div className="rounded-md bg-white p-5 !pt-3 shadow-custom">
      <Typography variant="medium2" className="text-tertiary-100 dark:text-white">
        {t('dashboard_ai.productivity_overview.title')}
      </Typography>
      <hr className="border-b-0.5 my-2 border-tertiary-600" />
      {score ? (
        <>
          <div className="flex flex-col">
            <Typography variant="regular4" className="text-tertiary-100 dark:text-white">
              {t('dashboard_ai.productivity_overview.you_have_done')}
            </Typography>
            <div className="my-1">
              <Typography variant="bold6xl" className="text-tertiary-100 dark:text-white">
                {Number(Number(score ?? 0) / 100).toFixed(2)}
                &nbsp;
                {t('dashboard_ai.productivity_overview.people')}
              </Typography>
            </div>
            <span className="mb-4 flex items-baseline gap-1">
              <span className="mt-[3px]">
                <ArrowUpIcon
                  className={cn(changeIsNegative ? 'rotate-180 !text-secondary-red' : '')}
                />
              </span>
              <Typography
                variant="medium5"
                className={cn(changeIsNegative ? 'text-secondary-red' : 'text-primary-green-100')}
              >
                {Number(change ?? 0).toFixed(2)}%
              </Typography>
              <Typography variant="light5" className="text-tertiary-200 dark:text-white">
                &nbsp;
                {'vs '}
                {t('dashboard_ai.productivity_overview.last_seven_days')}
              </Typography>
            </span>
          </div>
          <div className="">
            <div className="flex flex-col">
              <Line data={data} options={options} />
            </div>
          </div>
        </>
      ) : (
        <div className="my-16 flex flex-col items-center justify-center">
          <ProductivityClipArt />
          <Typography variant="light4" className="!mt-4 text-tertiary-200 dark:text-white">
            {t('dashboard_ai.productivity_overview.no_data')}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ProductivityOverview;
