import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { InputMaybe, ProductivityMetrics, Scalars } from 'gql/graphql';
import { gql } from 'graphql-request';
import { TGenericError } from 'types/TGenericError';
import clientUtil from 'utils/clientUtil';

type TVariables = {
  fromDate: InputMaybe<Scalars['ISO8601DateTime']>;
  toDate: InputMaybe<Scalars['ISO8601DateTime']>;
  fromDateBefore: InputMaybe<Scalars['ISO8601DateTime']>;
  toDateBefore: InputMaybe<Scalars['ISO8601DateTime']>;
};
type TData = {
  currentProductivityMetrics: ProductivityMetrics;
  productivityMetricsBefore: ProductivityMetrics;
};

const MOCK: TVariables = {
  fromDate: new Date().toISOString(),
  toDate: new Date().toISOString(),
  fromDateBefore: new Date().toISOString(),
  toDateBefore: new Date().toISOString()
};

export const useProductivityMetrics = (
  variables: TVariables = MOCK,
  options: Omit<UseQueryOptions<unknown, TGenericError, TData>, 'queryKey'> = {}
) => {
  const client = clientUtil();

  const graphql = gql`
    query productivityMetrics(
      $fromDate: ISO8601DateTime
      $toDate: ISO8601DateTime
      $fromDateBefore: ISO8601DateTime
      $toDateBefore: ISO8601DateTime
    ) {
      currentProductivityMetrics: productivityMetrics(fromDate: $fromDate, toDate: $toDate) {
        score
        change
        chartData {
          value
          timestamp
        }
      }
      productivityMetricsBefore: productivityMetrics(
        fromDate: $fromDateBefore
        toDate: $toDateBefore
        resolution: daily
      ) {
        score
        change
        chartData {
          value
          timestamp
        }
      }
    }
  `;

  return useQuery<unknown, TGenericError, TData>({
    ...options,
    queryKey: ['useProductivityMetrics'],
    queryFn: async () =>
      client.request({
        data: {
          gql: graphql,
          variables
        }
      })
  });
};
