import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { RecentActivityPage } from 'gql/graphql';
import { gql } from 'graphql-request';
import { TGenericError } from 'types/TGenericError';
import clientUtil from 'utils/clientUtil';

type TData = RecentActivityPage;

export const useRecentActivities = (
  options: Omit<UseQueryOptions<unknown, TGenericError, TData>, 'queryKey'> = {}
) => {
  const client = clientUtil();

  const graphql = gql`
    query recentActivities {
      recentActivities {
        nodes {
          id
          key
          parameters
          trackable {
            ... on GeneratedContent {
              id
              title
              updatedAt
            }
            ... on TranslationRequest {
              id
              version
              title
              updatedAt
            }
            ... on Comment {
              text
              translationRequest {
                title
                id
              }
              task {
                id
              }
              updatedAt
            }
            ... on Thread {
              id
              title
              updatedAt
            }
          }
        }
      }
    }
  `;

  return useQuery<unknown, TGenericError, TData>({
    ...options,
    queryKey: ['useRecentActivities'],
    queryFn: async () =>
      client.request({
        data: {
          gql: graphql
        }
      })
  });
};
